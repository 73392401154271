const baseFitmentFields = globalThis.Convermax.config?.baseFitmentFields || ['Year', 'Make', 'Model'];
const extraFitmentFields = globalThis.Convermax.config?.extraFitmentFields || ['Submodel'];
const wheelsFields = ['wheel_diameter', 'wheel_width', 'wheel_bolt_pattern'];
const tiresFields = ['tire_width', 'tire_aspect', 'tire_rim'];
const ignoreFields = [...wheelsFields, ...tiresFields];

const categorySelectionPageUrl = '/category-list/';

//Check that the store has been integrated with the BigCommerceApp
const getBrandsPageUrl = () => {
  const isAppInstalledStore =
    window.document.querySelector('#cm-brand-list') && window.location.pathname === '/brand-list/';
  return isAppInstalledStore ? '/brand-list/' : '/brands/';
};

globalThis.Convermax.handleProductCompareClick = () => {
  const productsIdsToCompare = [...window.document.querySelectorAll('input[name="products[]"]:checked')].map(
    (product) => product.dataset.compareId,
  );
  const compareBtn = window.document.body.querySelector('.cm_compare-btn');
  if (!compareBtn) {
    return;
  }

  compareBtn.classList.toggle('show', productsIdsToCompare.length > 1);
  compareBtn.href = `/compare/${productsIdsToCompare.join('/')}/`;
};

globalThis.Convermax.handleMobileProductImageClick = ({ target }) => {
  const clickedCardFigure = target.closest('.card-figure');
  window.document
    .querySelectorAll('.card-figure.active')
    .forEach((cardFigure) => cardFigure !== clickedCardFigure && cardFigure.classList.remove('active'));
  clickedCardFigure.classList.toggle('active');
};

export default {
  platform: 'bigcommerce',
  defaultView: 'productGrid',
  SearchRequestDefaults: {
    pageSize: 36,
    sort: window.Convermax.config?.defaultSort ?? 'relevance',
  },
  fitmentSearch: {
    baseFields: baseFitmentFields,
    extraFields: extraFitmentFields,
    categorySelectionPages: [
      { pathname: categorySelectionPageUrl, field: 'category' },
      { pathname: getBrandsPageUrl(), field: 'brand_name' },
    ],
    categorySelectionPageRedirect: window.Convermax.config?.categorySelectionPageRedirect ?? true,
  },
  facets: {
    rangedFacet: [{ fields: ['wheel_bore'], step: '1/10//1', slider: { mode: 'LowerBound' } }],
  },
  Widgets: [
    {
      name: 'SearchPage',
      location: '#cm_results',
      template: 'SearchPage',
    },
    {
      name: 'CategoryPage',
      type: 'SearchPage',
      location: ['category:not(#compare-page)', 'brand']
        .map((pageSelector) => `.${pageSelector} #product-listing-container`)
        .join(),
      template: 'MainContent',
    },
    {
      name: 'CategoryFacetPanel',
      type: 'FacetPanel',
      location: 'body:not(.home) .sideCategoryList',
      template: 'FacetPanelContainer',
      visibleIf: () => document.querySelector('.brand.main') || document.querySelector('.category.main'),
      ignoreFields,
    },
    {
      name: 'SearchHeader',
      sortEntries: {
        'relevance': 'Best Match',
        'name': 'A to Z',
        'name:desc': 'Z to A',
        'price': 'Price: Ascending',
        'price:desc': 'Price: Descending',
      },
    },
    { name: 'SearchResult' },
    {
      name: 'FacetPanel',
      ignoreFields,
    },
    {
      name: 'FacetBarWheels',
      type: 'FacetBar',
      fields: wheelsFields,
    },
    {
      name: 'FacetBarTires',
      type: 'FacetBar',
      fields: tiresFields,
    },
    {
      name: 'SearchBox',
      location: { replace: '.header .navPages-quickSearch form' },
      template: 'SearchBox',
    },
    {
      name: 'SearchBoxMobile',
      type: 'SearchBoxDialogButton',
      location: { replace: '.header .mbl-search-bar .navPages-quickSearch form' },
      template: 'searchBox/dialogButtonAdaptable',
    },
    {
      name: 'SearchBoxDialogButton',
      location: {
        insertAfter: '.mobileMenu-toggle',
        class: 'left cm_search-box-root__dialog_open-button_container',
        wrapper: 'a',
      },
      template: 'searchBox/dialogButtonMobile',
      visibleIf: () => !window.document.querySelector('.header .mbl-srch-icon'),
    },
    {
      name: 'HeaderVehicleWidget',
      type: 'VehicleWidget',
      location: {
        insertAfter: '.main-nav-container',
        class: 'cm_vehicle-widget__home_container',
      },
      template: 'fitmentSearch/collapsibleVehicleWidget',
      columnBreakpoint: 800,
    },
    {
      name: 'VehicleLabel',
      type: 'VehicleWidget',
      location: {
        replace: ['category', 'brand'].map((className) => `.${className} .container-header`).join(),
      },
      template: 'fitmentSearch/vehicleLabelContainer',
    },
    {
      name: 'SearchVehicleLabel',
      type: 'VehicleWidget',
    },
    {
      name: 'DialogVehicleWidget',
      type: 'VehicleWidget',
    },
    {
      name: 'Garage',
      location: {
        insertBefore: '.top-menu .navUser-item--cart',
        wrapper: 'li',
        class: 'cm_garage_container',
      },
      template: 'fitmentSearch/garage',
    },
    {
      name: 'VerifyFitment',
      type: 'VehicleWidget',
      location: { insertAfter: 'body.product-page-layout .productView-rating' },
      template: 'fitmentSearch/verifyFitment',
    },
    {
      name: 'FitmentTableTab',
      type: 'FitmentTable',
      location: { firstChildOf: '.product-page-layout .tabs[data-tab] .tab.fitment .tab-title' },
      template: 'fitmentSearch/fitmentTableTab',
    },
    {
      name: 'FitmentTable',
      location: {
        lastChildOf: '.product-page-layout .tabs-contents',
        class: 'tab-content fitment',
        id: 'tab-fitment',
      },
      template: 'fitmentSearch/fitmentTable',
    },
    {
      name: 'FacetTiles',
      location:
        window.location.pathname === '/brand-list/'
          ? '#cm_categories, #cm-brand-list'
          : '#cm_categories, ul.brandGrid',
      template: 'facetTiles/hierarchicalContainer',
      isVehicleWidgetDisabled: true,
    },
    {
      name: 'FacetDialog',
      template: 'FacetDialog',
      ignoreFields,
    },
    {
      name: 'SearchBoxDialog',
      template: 'SearchBoxDialog',
    },
    {
      name: 'vehicle-dialog',
      type: 'ContextDialog',
      template: 'fitmentSearch/dialog',
    },
  ],
};
